import { PlusCircleOutlined } from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import { Button, DatePicker, InputNumber, Space, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../../components/common/Loading';
import DriversPicker from '../../../components/dashboard/statistics/DriversPicker';
import FeeTableSection from '../../../components/dashboard/statistics/FeeTableSection';
import { GET_DRIVER_FEE_STATISTICS } from '../../../GraphQL/queries/statistics';
import ViewBasicLayout from '../../../layout/ViewBasicLayout';
import { DriverFeeData, SimpleDriverInfo, StyleSheet } from '../../../types';
import { graphqlErrorMessageAlert } from '../../../utils/tools';
import PrintModal from '../../../components/common/print/PrintModal';

const WHOLESALE_FUEL_BASE = 15;
const RESTAURANT_SPECIAL_FUEL_BASE = 10;

const { Text } = Typography;

const { RangePicker } = DatePicker;

const FeeStatistics = () => {
  const { t } = useTranslation();

  // Local state to replace Redux state
  const [isDriverModalVisible, setIsDriverModalVisible] = useState(false);
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);
  const [shippingDate, setShippingDate] = useState({
    start: moment().startOf('isoWeek').format('YYYY-MM-DD'),
    end: moment().endOf('isoWeek').format('YYYY-MM-DD'),
  });
  const [wholesaleFuelBaseInput, setWholesaleFuelBaseInput] =
    useState<number>(WHOLESALE_FUEL_BASE);
  const [restaurantFuelBaseInput, setRestaurantFuelBaseInput] =
    useState<number>(RESTAURANT_SPECIAL_FUEL_BASE);
  const [selectedDrivers, setSelectedDrivers] = useState<SimpleDriverInfo[]>(
    []
  );
  const [driverFeeData, setDriverFeeData] = useState<DriverFeeData>();

  const [getFeeReport, { loading }] = useLazyQuery(GET_DRIVER_FEE_STATISTICS, {
    variables: {
      input: {
        start_date: shippingDate.start,
        end_date: shippingDate.end,
        driver_id: selectedDrivers.length > 0 ? selectedDrivers[0].driverId : 0,
        wholesale_fuel_base: wholesaleFuelBaseInput,
        restaurant_special_fuel_base: restaurantFuelBaseInput
      },
    },
    onCompleted: (data) => {
      if (data && data.driverFeeData) {
        setDriverFeeData(data.driverFeeData);
      }
    },
    onError: (err) => graphqlErrorMessageAlert(err),
    fetchPolicy: 'cache-and-network',
  });

  // Date picker change handler
  const onDateChange = (_: any, dateStrings: [string, string]) => {
    setShippingDate({
      start: dateStrings[0]
        ? moment(dateStrings[0]).format('YYYY-MM-DD')
        : moment().startOf('isoWeek').format('YYYY-MM-DD'),
      end: dateStrings[1]
        ? moment(dateStrings[1]).format('YYYY-MM-DD')
        : moment().endOf('isoWeek').format('YYYY-MM-DD'),
    });
  };

  useEffect(() => {
    if (selectedDrivers.length > 0) {
      getFeeReport();
    }
  }, [getFeeReport, selectedDrivers, shippingDate, wholesaleFuelBaseInput, restaurantFuelBaseInput]);

  return (
    <ViewBasicLayout title={t('feeStatistics.title')}>
      <div style={styles.headerContainer}>
        <Space direction="vertical">
          <RangePicker
            ranges={{
              [`${t('datePicker.period.week')}`]: [
                moment().startOf('isoWeek'),
                moment().endOf('isoWeek'),
              ],
              [`${t('datePicker.period.firstHalfMonth')}`]: [
                moment().startOf('month'),
                moment().startOf('month').add(14, 'd'),
              ],
              [`${t('datePicker.period.secondHalfMonth')}`]: [
                moment().startOf('month').add(15, 'd'),
                moment().endOf('month'),
              ],
              [`${t('datePicker.period.month')}`]: [
                moment().startOf('month'),
                moment().endOf('month'),
              ],
            }}
            onChange={onDateChange}
            defaultValue={[
              moment(shippingDate.start),
              moment(shippingDate.end),
            ]}
          />
          <Space>
            <Text>{`${t(
              'feeStatistics.wholesaleBaseInput', { amount: WHOLESALE_FUEL_BASE}
            )}:`}</Text>
            <InputNumber
              defaultValue={WHOLESALE_FUEL_BASE}
              onChange={(val) => {
                setWholesaleFuelBaseInput(val);
              }}
            />
            <Text style={{paddingLeft: 5}}>{`${t(
              'feeStatistics.RestaurantSpecialBaseInput', { amount: RESTAURANT_SPECIAL_FUEL_BASE}
            )}:`}</Text>
            <InputNumber
              defaultValue={RESTAURANT_SPECIAL_FUEL_BASE}
              onChange={(val) => {
                setRestaurantFuelBaseInput(val);
              }}
            />
          </Space>
        </Space>
        <Button
          icon={<PlusCircleOutlined />}
          size="large"
          onClick={() => setIsDriverModalVisible(true)}
        >
          {t('statistics.selectDrivers')}
        </Button>
      </div>
      {loading ? (
        <Loading />
      ) : driverFeeData && selectedDrivers.length > 0 ? (
        <div style={styles.mainSection}>
          <Button
            onClick={() => {
              setIsPrintModalVisible(true);
            }}
          >
            {t('common.print')}
          </Button>

          <FeeTableSection
            shippingDate={shippingDate}
            driverFeeData={driverFeeData}
          />
        </div>
      ) : (
        <div style={styles.noDriverContainer}>
          <Button
            type="primary"
            shape="round"
            icon={<PlusCircleOutlined />}
            size="large"
            onClick={() => setIsDriverModalVisible(true)}
            style={{ marginBottom: 10 }}
          >
            {t('statistics.selectDrivers')}
          </Button>
          <Text style={{ fontSize: 18 }}>{t('feeStatistics.pickOne')}</Text>
        </div>
      )}

      <DriversPicker
        visible={isDriverModalVisible}
        setVisible={setIsDriverModalVisible}
        setSelectedDrivers={setSelectedDrivers}
        isMultiple={false}
      />

      {driverFeeData && (
        <PrintModal
          modalLoading={loading}
          mode="driverFee"
          data={{
            driverFeeData: {
              shippingDate: shippingDate,
              driverFeeData: driverFeeData,
            },
          }}
          visible={isPrintModalVisible}
          setVisible={setIsPrintModalVisible}
        />
      )}
    </ViewBasicLayout>
  );
};

const styles: StyleSheet = {
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  mainSection: {
    paddingTop: 10,
  },
  noDriverContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 10,
    minHeight: 300,
    justifyContent: 'center',
  },
};

export default FeeStatistics;
